import { Component, Input } from '@angular/core';
import { EventCardModule } from '../../module/event-card/event-card.module';
import { NgForOf } from '@angular/common';
import { UiModule } from '../../ui/ui.module';

@Component({
  selector: 'app-frame-page',
  standalone: true,
    imports: [
        EventCardModule,
        NgForOf,
        UiModule,
    ],
  templateUrl: './frame-page.component.html',
  styleUrl: './frame-page.component.scss'
})
export class FramePageComponent {
  @Input() titlePage = '';

}
