
<button class="button" [class.disabled]="isDisabled" *ngIf="typeButton=== 'default'; else sliderAction"
     [ngClass]="
     {'button--primary': type === 'primary',
      'button--secondary': type === 'secondary',
      'button--small': size === 'small',
      'button--medium': size === 'medium',
      'button--big': size === 'big',
      }">
  <div class="button__wrapper">
    <ng-content></ng-content>
  </div>
</button>

<ng-template #sliderAction>
  <div class="slider-action" [class.disabled]="isDisabled">
    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 5L16 12L9 19" stroke="#007ED3" stroke-width="2" stroke-linecap="square"/>
    </svg>
  </div>
</ng-template>
