import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from './button/button.component';
import { PaginationItemComponent } from './pagination-item/pagination-item.component';
import { UiIconComponent } from './ui-icon/ui-icon.component';
import {Router, RouterLink, RouterModule} from "@angular/router";



@NgModule({
    declarations: [
        ButtonComponent,
        PaginationItemComponent,
        UiIconComponent
    ],
    exports: [
        ButtonComponent,
        PaginationItemComponent,
        UiIconComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
    ]
})
export class UiModule { }
