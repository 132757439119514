import {Injectable} from "@angular/core";
import {BehaviorSubject, filter} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {NavigationEnd, Router} from "@angular/router";
import {LocaleService} from "./locale.service";

@Injectable({
  providedIn: "root"
})
export class LangService {

  private languageSubject = new BehaviorSubject<string>('ru');
  currentLanguage$ = this.languageSubject.asObservable();

  readonly currentLang = window.location.href.includes('/en') ? 'en' : 'ru';

  constructor(private localeService: LocaleService) {}

  setLanguage(lang: string) {
    this.languageSubject.next(lang);
    this.updateLangAttribute(lang);
    this.localeService.setLocale(lang);
  }

  getLanguage(): string {
    return this.languageSubject.getValue();
  }

  private updateLangAttribute(lang: string) {
    document.documentElement.lang = lang;
  }
}
