<div class="event" (click)="navigate()">
  <div class="event__wrapper">
    <div class="event__img">
      <img [src]="environment.imageUrl + event.get_thumb" [alt]="event.title">
    </div>
    <div class="event__content">
      <div class="date" *ngIf="event.created">{{event.created | localizedDate : 'dd MMMM yyyy' }}</div>
      <div class="caption" *ngIf="event.title">{{event.title}}</div>
      <div class="description" *ngIf="event.intro" [innerHTML]="event.intro"></div>
    </div>
  </div>
</div>
