import {Component, Input} from '@angular/core';
import {Router} from "@angular/router";
import { IEvent } from '../../types';
import { environment } from '../../../environments/environment';
import {LocaleService} from "../../service/locale.service";

@Component({
  selector: 'app-event-card',
  templateUrl: './event-card.component.html',
  styleUrl: './event-card.component.scss'
})
export class EventCardComponent {
  @Input() event!: IEvent;

  constructor(private router: Router, private locale: LocaleService) {
  }
  navigate() {
    this.router.navigate(['events/' + this.event.slug]);
  }

  protected readonly environment = environment;
}
