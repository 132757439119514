import { NgModule } from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import { EventCardComponent } from './event-card.component';
import {RouterLink} from "@angular/router";
import {LocalizedDatePipe} from "../../pipe/date-locale.pipe";



@NgModule({
    declarations: [
        EventCardComponent,
        LocalizedDatePipe,
    ],
    exports: [
        EventCardComponent,
        LocalizedDatePipe
    ],
  imports: [
    CommonModule,
    NgOptimizedImage,
    RouterLink
  ]
})
export class EventCardModule { }
