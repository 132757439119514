import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'ui-button',
  templateUrl: './button.component.html',
  styleUrl: './button.component.scss'
})
export class ButtonComponent {
  @Output() onClick = new EventEmitter();
  @Input() typeButton: 'default' | 'slider-action' = 'default';
  @Input() type: 'secondary' | 'primary' = 'primary';
  @Input() isDisabled = false;
  @Input() size: 'big' | 'medium' | 'small' = 'big';

  constructor() {
  }
}
